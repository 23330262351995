/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './app/variables.scss';

@include mat.core();

$primary-palette: mat.m2-define-palette(
  (
    50: $primary50,
    100: $primary50,
    200: $primary200,
    300: $primary200,
    400: $primary200,
    500: $primary,
    600: $primary,
    700: $primary,
    800: $primary,
    900: $primary900,
    contrast: (
      50: $headerText,
      100: $headerText,
      200: $headerText,
      300: $headerText,
      400: $headerText,
      500: $textColor,
      600: $textColor,
      700: $textColor,
      800: $textColor,
      900: $textColor
    )
  )
);

$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $primary-palette
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);

@include mat.all-component-themes($custom-theme);

@import 'node_modules/ngx-toastr/toastr';

// material-icons
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/MaterialIconsOutlined-Regular.otf');
}

.material-icons,
.material-icons-outlined {
  /* stylelint-disable */
  font-family: 'Material Icons';
  /* stylelint-enable */
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr; /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased; /* Support for Safari and Chrome. */
  text-rendering: optimizelegibility; /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale; /* Support for IE. */
  font-feature-settings: 'liga';
}

body {
  background: white;
  margin: 0;
  font-family: 'Siemens Sans', sans-serif;
}

a,
p,
span,
ul,
li,
div {
  font-family: 'Siemens Sans', sans-serif;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 4rem !important;
  font-family: 'Siemens Sans Bold', sans-serif;
}

h2 {
  color: var(--headerText);
  font-family: 'Siemens Sans Bold', sans-serif;
  font-size: 1.8rem;
  padding: 1rem 0;
}

a {
  color: var(--primary);
  text-decoration: none;
}

a:hover {
  color: var(--primary900);
}

.transparent-dialog-panel {
  > mat-dialog-container {
    box-shadow: none;
    background: transparent;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--focused {
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    border-color: var(--primary) !important;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled).mdc-text-field--focused {
  .mdc-floating-label--float-above,
  .mdc-floating-label {
    color: var(--primary) !important;
  }
}
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: var(--primary) !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--primary) !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: var(--primary200) !important;
}
.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: var(--primary) !important;
}
